<template>
    <div style="margin:0 10px">
        <router-view></router-view> 
        <div class="card">
            <!-- search -->
            <div class="search d-flex a-center j-sb">
                <div class="d-flex a-center">
                    <div style="width:160px;margin-right:10px;">
                        <el-input type="text" v-model="query.a" placeholder="模板名称"></el-input>  
                    </div>
                    <el-button type="primary">查询</el-button>
                    <el-button type="primary" @click="$router.push({name:'module_add'})">+ 添加</el-button>    
                </div>

            </div>

            <!-- handle -->
            <div class="handle"></div>

            <!-- table -->
            <div class="table" style="margin-top:20px;">
                <el-table
                    :data="tableData"
                    style="width: 100%;"
                    v-loading="loading"
                    element-loading-background="rgba(255,255,255, 0.6)"
                    :header-cell-style="{background:'#f8f8f9',color:'#606266',borderBottom:'1px solid #e8eaec',padding:'8px 0',fontWeight:'700'}"
                >
                    <el-table-column prop="templateName" label="模板名称" align="center"></el-table-column>
                    <el-table-column prop="deptName" label="关联院校" align="center"></el-table-column>
                    <el-table-column prop="" label="操作" align="center" width="180">
                        <template slot-scope="scope">
                            <router-link :to="{name:'module_edit',query:scope.row}" style="color: rgb(33, 159, 238);">编辑</router-link>
                            <!-- <el-popconfirm
                                style="margin-left: 10px;"
                                title="是否确定克隆？"
                                @confirm="handleCopy(scope.row)"
                            >
                                <el-button type="text" slot="reference" size="small">克隆</el-button>
                            </el-popconfirm> -->
                            <el-button style="margin-left: 10px;" type="text" size="small" @click="handleClickClone(scope.row)">克隆</el-button>
                            <el-popconfirm
                                style="margin-left: 10px;"
                                title="是否确定删除？"
                                @confirm="handleDel(scope.row)"
                            >
                                <el-button type="text" slot="reference" size="small">删除</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- page -->
                <div style="margin-top:20px;text-align:center">
                    <el-pagination
                        background
                        layout="total, prev, pager, next, sizes"
                        :total="total"
                        :page-sizes="pageSizes"
                        :page-size="query.size"
                        @size-change="onSize"
                        @current-change="onCurrPage"
                        @prev-click="onCurrPage"
                        @next-click="onCurrPage"
                    >
                    </el-pagination>
                </div>
            </div>
            <el-dialog
                title="克隆"
                :visible.sync="clone.cloneConfigureShow"
                center
                :append-to-body="true"
                width="600px"
            >
                <el-form ref="cloneform" :model="clone.form" label-width="80px">
                    <el-form-item label="模板名称">
                        <el-input v-model="clone.form.templateName"></el-input>
                    </el-form-item>
                    <el-form-item label="院校" v-if="clone.manager">
                        <el-select
                            style="width: 100%;"
                            v-model="clone.deptId"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="请输入院校查询"
                            :remote-method="remoteMethod"
                            :loading="clone.cloneLoading">
                            <el-option
                            v-for="item in clone.select"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">立即提交</el-button>
                    </el-form-item>
                </el-form>

            </el-dialog>
        </div>
    </div>
</template>

<script>
import {lists,del,get_module,create,clone as setClone} from '@/request/api/module.js'
import {lists as college_lists} from '@/request/api/college.js'
import {get_user} from '@/request/api/user.js'
export default {
    data(){
        return {
            tableData: [],
            query:{name:'',page:1,size:10},
            pageSizes:[10,30,50,100],
            total:0,
            loading:false,
            isEdit:false ,//编辑状态
            
            clone:{
                cloneConfigureShow:false,
                cloneLoading:false,
                select:[],
                deptId:'',//院校id
                manager:false,
                form:{
                    templateName:'',
                    templateId:'',
                }
            }
        }
    },
    watch:{
        $route(val ,old){
            //返回刷新
            if(val.name=='module') {
                this.init()
            }
        }
    },
    created(){
        this.init()
        this.get_role()
        this.get_college_lists('')
    },

    methods:{
        init(){
            lists(this.query).then(res=>{
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },

        //获取角色
        get_role(){
            get_user(this.$store.state.user.id).then(res=>{
                res.data.roleList && res.data.roleList.forEach(item=>{
                    if(item.slug == 'super_manager') {
                        this.clone.manager = true;
                        return;
                    } 
                })
            })
        },

        //院校列表
        get_college_lists(query){
            college_lists({name:query,page:1,size:10000}).then(res=>{
                this.clone.select = res.data.list
            })
        },

        //select搜索院校
        remoteMethod(query) {
            this.get_college_lists(query)
        },

        //列表点击克隆按钮
        handleClickClone(data){
            this.clone.cloneConfigureShow = true
            this.clone.form.templateId = data.id
            this.clone.form.templateName = data.templateName+'_克隆'
        },

        //提交克隆
        onSubmit(){
            var data = JSON.parse(JSON.stringify(this.clone.form))
            if(this.clone.manager) {
                data['deptId'] = this.clone.deptId
            }
            setClone(data).then(res=>{
                if(res.code!=0) return this.$notify.error({title:'错误',message: res.msg});
                this.init()
                this.$notify({title: '成功',message: res.msg,type: 'success'});
                this.clone.cloneConfigureShow = false
            })
        },
        //删除
        handleDel(data){
            del({ids:[data.id]}).then(res=>{
                if(res.code!=0) return this.$notify.error({title:'错误',message: res.msg});
                this.init()
                this.$notify({title: '成功',message: res.msg,type: 'success'});
            })
        },
        handleCopy(data) {
            get_module(data.id).then(res=>{
                if(res.code!=0) return this.$notify.error({title:'错误',message: res.msg});
                let _t = res.data.template
                let _s = res.data.samples
                let _r = res.data.rules
                let _data = {
                    rules:[],
                    samples:[], //content result title
                    template:{
                        templateName:_t.templateName + ' 克隆'
                    }
                };
                _r && _r.forEach(item=>{
                    let _rules = {
                        groupName:item.groupName,
                        items:[]
                    }
                    item.children && item.children.forEach((itx,inx)=>{
                        _rules['items'].push({id:itx.id,maxScore:itx.maxScore})
                    })

                    _data.rules.push(_rules)
                })
                
                _s && _s.forEach(item=>{
                    let _samples = {
                        content:item.content,
                        result:item.result,
                        title:item.title
                    };
                    _data.samples.push(_samples)
                })

                create(_data).then(res=>{
                    if(res.code!=0) return this.$notify.error({title:'错误',message: res.msg});
                    this.init()
                    this.$notify({title: '成功',message: res.msg,type: 'success'});
                })
            })
        },

        //每页条数
        onSize(val){
            this.query.size=val
            this.init()
        },
        onCurrPage(val){
            this.query.page = val
            this.init()
        }
    }
}
</script>


<style scoped lang="less">


</style>

